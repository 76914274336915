import { assertIsString } from '../../../../lib/test-and-assert/assert-base';
import { assertIsRawInputInfo } from '../assert';
import { testIsUndefined } from '../../../../lib/test-and-assert/test-base';
import { testIsObject, testObjectHasKey } from '../../../../lib/test-and-assert/test-base';
import { globalLogger } from '../../../../qaamgo/helper/global-logger';
import { Input } from '../lib/input';
import {
    INPUT_STATUS_UPLOADER_LOADING,
    INPUT_STATUS_UPLOADER_SUBMITTING,
    INPUT_TYPE_ONEDRIVE
} from '../consts';
import {
    GLOBAL_EVENT_LIMITS_INPUT_TOO_LARGE,
    INPUT_EVENT_LOADING,
    INPUT_EVENT_SUBMITTING
} from '../event';
import { assertIsApiError } from '../../../../lib/test-and-assert/assert-api';
import {
    API_ERROR_LIMITS_INPUT_TOO_LARGE,
    API_ERROR_UNKNOWN,
    API_ERROR_URL_TOO_LONG
} from '../../../api/consts';
import { FailInputError } from '../uploader';
import $ from 'jquery';

/**
 * @constructor
 */
function OnedriveConfig() {
    /** @type {number} */
    this.maxFileSize = 8589934592;

    /** @type {?string} */
    this.clientId = null;

    /** @type {?string} */
    this.redirectUri = window.location.origin + '/onedrive/filepicker';
}

export { OnedriveConfig };

class AddOnedrive {
    /**
     * @param {Uploader} uploader
     * @param {OnedriveConfig} config
     */
    constructor(uploader, config) {
        /** @type {Uploader} */
        this._uploader = uploader;

        this._config = new OnedriveConfig();

        if (testIsObject(config)) {
            if (testObjectHasKey(config, 'maxFileSize')) {
                this._config.maxFileSize = config.maxFileSize;
            }
            if (testObjectHasKey(config, 'clientId')) {
                this._config.clientId = config.clientId;
            }
        }
    }

    add() {
        /** @type {Uploader} */
        var _uploader = this._uploader;
        /** @type {OnedriveConfig} */
        var _config = this._config;

        function loadPicker() {
            if (sessionStorage.getItem('PLAYWRIGHT') === 'true') {
                //return;
            }

            var script = document.createElement('script');
            script.src = 'https://js.live.net/v7.2/OneDrive.js';
            script.onload = function () {
                launchPicker();
            };
            script.onerror = function () {
                globalLogger.log('qgMultiUpload onedrive script load error');
            };
            document.body.appendChild(script);
        }

        function launchPicker() {
            var odOptions = {
                clientId: _config.clientId,
                action: 'download',
                multiSelect: true,
                advanced: {
                    queryParameters: "select=name,size,id,@microsoft.graph.downloadUrl",
                    filter: "file",
                    fileSizeLimit: _config.maxFileSize,
                    redirectUri: _config.redirectUri,
                    scopes: ['Files.Read'],
                },
                success: function (response) {
                    handleFiles(response);
                },
                cancel: function () {
                    globalLogger.log('qgMultiUpload onedrive picker cancelled');
                },
                error: function (error) {
                    globalLogger.log('qgMultiUpload onedrive picker error', {
                        error: error
                    });
                }
            };

            OneDrive.open(odOptions);
        }

        function handleFiles(response) {
            globalLogger.log('qgMultiUpload onedrive response received', {
                response: response
            });

            if (!response.value || !Array.isArray(response.value)) {
                globalLogger.log('qgMultiUpload onedrive invalid response', {
                    response: response
                });
                return;
            }

            response.value.forEach(function (file) {
                try {
                    globalLogger.log('qgMultiUpload processing file', {
                        file: file
                    });

                    var size = file.size;
                    if (typeof size !== 'number') {
                        size = 0;
                        globalLogger.log('qgMultiUpload invalid file size, defaulting to 0', {
                            originalSize: file.size,
                            file: file
                        });
                    }

                    var name = file.name;
                    if (!name || typeof name !== 'string') {
                        name = 'onedrive_file';
                        globalLogger.log('qgMultiUpload invalid file name, using default', {
                            originalName: file.name,
                            file: file
                        });
                    }

                    var downloadUrl = file['@microsoft.graph.downloadUrl'];
                    if (!downloadUrl) {
                        globalLogger.log('qgMultiUpload missing download URL', {
                            file: file
                        });
                        return;
                    }

                    var fileId = file.id;
                    if (!fileId) {
                        globalLogger.log('qgMultiUpload missing file ID', {
                            file: file
                        });
                        return;
                    }

                    globalLogger.log('qgMultiUpload calling helper', {
                        name: name,
                        size: size,
                        fileId: fileId,
                        downloadUrl: downloadUrl
                    });

                    helper(name, size, fileId, downloadUrl);

                } catch (error) {
                    globalLogger.log('qgMultiUpload error processing file', {
                        error: error,
                        file: file
                    });
                }
            });
        }

        function helper(name, size, fileId, url) {
            assertIsString(url);

            const engine = 'auto';
            url = url.trim();

            var callback = function (localId) {
                var deferred = $.Deferred();

                var jobId = _uploader.getJob().getId();

                _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_SUBMITTING);
                _uploader.getInput(localId).setName(name);

                _uploader.dispatchInputEvent(INPUT_EVENT_SUBMITTING, localId);

                if (url.length === 0) {
                    var failInputError = new FailInputError(API_ERROR_UNKNOWN);

                    _uploader.failInput(localId, failInputError);

                    deferred.resolve();

                    return deferred;
                }

                if (url.length > 4000) {
                    const failInputError = new FailInputError(API_ERROR_URL_TOO_LONG);

                    _uploader.failInput(localId, failInputError);

                    deferred.resolve();

                    return deferred;
                }

                _uploader
                    .getApiHelper()
                    .addExternalUrl(jobId, url, engine)
                    /**
                     * @param {object}   inputInfo
                     */
                    .done(function (rawInputInfo) {
                        assertIsRawInputInfo(rawInputInfo);

                        var inputId = rawInputInfo.id;

                        _uploader.getInput(localId).setInputId(inputId);
                        _uploader.getInput(localId).setStatus(INPUT_STATUS_UPLOADER_LOADING);

                        _uploader.dispatchInputEvent(INPUT_EVENT_LOADING, localId);

                        _uploader.waitForRemoteInput(localId);

                        deferred.resolve();
                    })
                    .fail(function (apiError) {
                        assertIsApiError(apiError);

                        var failInputError = new FailInputError(apiError);

                        _uploader.failInput(localId, failInputError);

                        deferred.resolve();
                    });

                return deferred;
            };

            var input = new Input(INPUT_TYPE_ONEDRIVE);
            input.setName(name).setCallback(callback);
            _uploader._queueInput(input);
        }

        loadPicker();
    }

    click() {
        this._uploader.addOnedrive();
    }
}

export { AddOnedrive };