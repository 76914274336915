import {
    assertIsFunction,
    assertIsNotNull,
    assertIsNumber,
    assertIsString,
    assertIsTrue,
    assertIsUUID4,
} from '../../../../lib/test-and-assert/assert-base';
import { assertIsInputStatusUploader, assertIsInputType } from '../assert';

import {
    INPUT_STATUS_UPLOADER_DELETING,
    INPUT_STATUS_UPLOADER_DONE,
    INPUT_STATUS_UPLOADER_LOADING,
    INPUT_STATUS_UPLOADER_PASSWORD_MISSING,
    INPUT_STATUS_UPLOADER_QUEUED,
    INPUT_STATUS_UPLOADER_SUBMITTING,
    INPUT_TYPE_FILE_UPLOAD,
    isInputInApi,
} from '../consts';

/**
 * A class which bundles some settings and data for a single input
 *
 * @param {string} type
 *
 * @constructor
 */
function Input(type) {
    assertIsInputType(type);

    this._type = type;

    /** @type {string} */
    this._name = '';

    /** @type {?Function} */
    this._callback = null;

    /** @type {?number} */
    this._localId = null;

    /** @type {?number} */
    this._size = null;

    /** @type {?string} */
    this._inputId = null;

    /** @type {string} */
    this._status = INPUT_STATUS_UPLOADER_QUEUED;

    /** @type {?Object} */
    this._metadata = null;

    /** @type {?string} */
    this._content_type = null;

    /** @type {?string} */
    this._engine = null;

    /** @type {?string} */
    this._source = null;

    /**
     * If the input is a file upload then this property stores the 'data' object from the
     * jQuery file uploader
     *
     * @type {?Object}
     */
    this._uploadData = null;
}

/**
 * @return {string}
 */
Input.prototype.getType = function () {
    assertIsInputType(this._type);
    return this._type;
};

/**
 * @return {number}
 */
Input.prototype.getLocalId = function () {
    return this._localId;
};

/**
 * @param {Object} metadata
 *
 * @return {Input}
 */
Input.prototype.setMetadata = function (metadata) {
    this._metadata = metadata;

    return this;
};

/**
 * @return {?Object}
 */
Input.prototype.getMetadata = function () {
    return this._metadata;
};

/**
 * @param {string} contentType
 *
 * @return {Input}
 */
Input.prototype.setContentType = function (contentType) {
    this._content_type = contentType;

    return this;
};

/**
 * @return {?Object}
 */
Input.prototype.getContentType = function () {
    return this._content_type;
};

/**
 * @param {string} engine
 * @returns {Input}
 */
Input.prototype.setEngine = function (engine) {
    this._engine = engine;

    return this;
};

/**
 * @return {?String}
 */
Input.prototype.getEngine = function () {
    return this._engine;
};

/**
 * @param {string} source
 * @returns {Input}
 */
Input.prototype.setSource = function (source) {
    this._source = source;

    return this;
};

/**
 * @return {?String}
 */
Input.prototype.getSource = function () {
    return this._source;
};

/**
 * @param {number} localId
 *
 * @return {Input}
 */
Input.prototype.setLocalId = function (localId) {
    assertIsNumber(localId);
    assertIsTrue(localId >= 0);
    this._localId = localId;

    return this;
};

/**
 * @return {?Function}
 */
Input.prototype.getCallback = function () {
    return this._callback;
};

/**
 * @param {Function} callback
 *
 * @return {Input}
 */
Input.prototype.setCallback = function (callback) {
    assertIsFunction(callback);

    this._callback = callback;

    return this;
};

/**
 * @return {string}
 */
Input.prototype.getName = function () {
    return this._name;
};

/**
 * @param {string} name
 *
 * @return {Input}
 */
Input.prototype.setName = function (name) {
    assertIsString(name);

    this._name = name;

    return this;
};

/**
 * @return {number}
 */
Input.prototype.getSize = function () {
    return this._size;
};

/**
 * @param {number} size
 *
 * @return {Input}
 */
Input.prototype.setSize = function (size) {
    assertIsNumber(size);
    assertIsTrue(size >= 0);

    this._size = size;

    return this;
};

/**
 * @return {string}
 */
Input.prototype.getInputId = function () {
    return this._inputId;
};

/**
 * @param {string} inputId
 * @return {Input}
 */
Input.prototype.setInputId = function (inputId) {
    assertIsUUID4(inputId);

    this._inputId = inputId;

    return this;
};

/**
 * @return {string}
 */
Input.prototype.getStatus = function () {
    return this._status;
};

/**
 * @param {string} status
 * @return {Input}
 */
Input.prototype.setStatus = function (status) {
    assertIsInputStatusUploader(status);

    this._status = status;

    return this;
};

/**
 * @param {Object} uploadData
 *
 * @return {Input}
 */
Input.prototype.setUploadData = function (uploadData) {
    // If the next line fails then there is a severe logic bug somewhere
    assertIsTrue(this._type === INPUT_TYPE_FILE_UPLOAD);

    this._uploadData = uploadData;

    return this;
};

/**
 * If the input is a file upload then this returns the 'data' object from the jquery uplaoder.
 * For any other type it will epxlode. If this happens then there is a logic error somewhere
 *
 * @return {?Object}
 */
Input.prototype.getUploadData = function () {
    // If one of the next two lines fail then there is a severe logic bug somewhere
    assertIsTrue(this._type === INPUT_TYPE_FILE_UPLOAD);
    assertIsNotNull(this._uploadData);

    return this._uploadData;
};

/**
 * Current statuses:
 *
 * QUEUED
 * FAILED
 * DELETED
 * DELETING
 * ADDING
 * PASSWORD_MISSING
 * DONE
 *
 * Note: please make sure to update the functions below when new statuses are added
 */

/*
 * 'In Api' means that the input is somehow active in the API right now
 *
 * 'Not in Api' are all inputs outside the API, e.g. queued, failed and deleted inputs.
 * For an up-to-date list of such statii please refer to the function isInputInApi
 *
 * @return {boolean}
 */
Input.prototype.isInApi = function () {
    return isInputInApi(this._status);
};

/**
 * @return {boolean}
 */
Input.prototype.isQueued = function () {
    return this._status === INPUT_STATUS_UPLOADER_QUEUED;
};

/**
 * @return {boolean}
 */
Input.prototype.isSubmitting = function () {
    return this._status === INPUT_STATUS_UPLOADER_SUBMITTING;
};

/**
 * @return {boolean}
 */
Input.prototype.isLoading = function () {
    return this._status === INPUT_STATUS_UPLOADER_LOADING;
};

/**
 * @return {boolean}
 */
Input.prototype.isDone = function () {
    return this._status === INPUT_STATUS_UPLOADER_DONE;
};

/**
 * @return {boolean}
 */
Input.prototype.isPasswordMissing = function () {
    return this._status === INPUT_STATUS_UPLOADER_PASSWORD_MISSING;
};

/**
 * @return {boolean}
 */
Input.prototype.isDeleting = function () {
    return this._status === INPUT_STATUS_UPLOADER_DELETING;
};

/**
 * Returns true if input is a file which is currently uploading
 * @return {boolean}
 */
Input.prototype.isFileUploading = function () {
    // If it is not a file upload then always return false
    if (this._type !== INPUT_TYPE_FILE_UPLOAD) {
        return false;
    }

    return this._status === INPUT_STATUS_UPLOADER_LOADING;
};

/**
 * Returns true if input is a file which is currently uploading
 * @return {boolean}
 */
Input.prototype.isRemoteInputDownloading = function () {
    // If it is not some sort of remote url then return false
    if (this._type === INPUT_TYPE_FILE_UPLOAD) {
        return false;
    }

    return this._status === INPUT_STATUS_UPLOADER_LOADING;
};

export { Input };
