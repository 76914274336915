import { assertIsInputStatusUploader } from './assert';

/**
 * There are all the statuses that an input can have in the API. They are not the same as
 * the input status in the uploader!
 *
 * WARNING: the values of each status is defined in the API! Do NOT change them here!
 */
var INPUT_STATUS_API_READY = 'ready'; // all these values are defined in the api, do not change them!
var INPUT_STATUS_API_FAILED = 'failed';
var INPUT_STATUS_API_DOWNLOADING = 'downloading';
var INPUT_STATUS_API_CANCELED = 'canceled';

export { INPUT_STATUS_API_READY };
export { INPUT_STATUS_API_FAILED };
export { INPUT_STATUS_API_DOWNLOADING };
export { INPUT_STATUS_API_CANCELED };

var INPUT_STATUS_API = [
    INPUT_STATUS_API_READY,
    INPUT_STATUS_API_FAILED,
    INPUT_STATUS_API_DOWNLOADING,
    INPUT_STATUS_API_CANCELED,
];

export { INPUT_STATUS_API };

/**
 * These are the statii which an input can have is the uploader.
 *
 * INFO: THESE ARE NOT THE SAME AS IN 'input-status-api.js'! Those are the statii which an
 *       input can have in the api itself!
 *
 * Note 2: Please make sure to update isInputInApi() if new statuses are added
 */
var INPUT_STATUS_UPLOADER_QUEUED = 'input-status-queued'; // Queued inputs are not in the API
var INPUT_STATUS_UPLOADER_SUBMITTING = 'input-status-submitting-to-api'; // This is the state between: 1) "input left the queue and was sent to API" and 2) API answered
var INPUT_STATUS_UPLOADER_LOADING = 'input-status-loading'; // This is either a) downloading some sort of remote input or b) uploading a file
var INPUT_STATUS_UPLOADER_DONE = 'input-status-done';
var INPUT_STATUS_UPLOADER_SETTING_PASSWORD = 'input-status-setting-password';
var INPUT_STATUS_UPLOADER_PASSWORD_MISSING = 'input-status-password-missing';
var INPUT_STATUS_UPLOADER_DELETING = 'input-status-deleting'; // This is the state between 1) user pressed the delete button and 2) API answered
var INPUT_STATUS_UPLOADER_DELETED = 'input-status-deleted'; // Deleted inputs are not in the API
var INPUT_STATUS_UPLOADER_FAILED = 'input-status-failed'; // Failed inputs are not in the API

export { INPUT_STATUS_UPLOADER_QUEUED };
export { INPUT_STATUS_UPLOADER_SUBMITTING };
export { INPUT_STATUS_UPLOADER_LOADING };
export { INPUT_STATUS_UPLOADER_DONE };
export { INPUT_STATUS_UPLOADER_SETTING_PASSWORD };
export { INPUT_STATUS_UPLOADER_PASSWORD_MISSING };
export { INPUT_STATUS_UPLOADER_DELETING };
export { INPUT_STATUS_UPLOADER_DELETED };
export { INPUT_STATUS_UPLOADER_FAILED };

var INPUT_STATUS_UPLOADER = [
    INPUT_STATUS_UPLOADER_QUEUED,
    INPUT_STATUS_UPLOADER_SUBMITTING,
    INPUT_STATUS_UPLOADER_LOADING,
    INPUT_STATUS_UPLOADER_DONE,
    INPUT_STATUS_UPLOADER_SETTING_PASSWORD,
    INPUT_STATUS_UPLOADER_PASSWORD_MISSING,
    INPUT_STATUS_UPLOADER_DELETING,
    INPUT_STATUS_UPLOADER_DELETED,
    INPUT_STATUS_UPLOADER_FAILED,
];

export { INPUT_STATUS_UPLOADER };

/**
 * Returns true if the input is in the api.
 *
 * Note: This needs updating if new 'INPUT_STATUS_*" states are added
 *
 * @param {string} status
 *
 * @return {boolean}
 */
var isInputInApi = function (status) {
    assertIsInputStatusUploader(status);

    if (status === INPUT_STATUS_UPLOADER_QUEUED) {
        return false;
    }

    if (status === INPUT_STATUS_UPLOADER_DELETED) {
        return false;
    }

    if (status === INPUT_STATUS_UPLOADER_FAILED) {
        return false;
    }

    return true;
};

export { isInputInApi };

var INPUT_TYPE_EXTERNAL_URL = 'type-external-url';
var INPUT_TYPE_FILE_UPLOAD = 'type-upload';
var INPUT_TYPE_GDRIVE = 'type-gdrive';
var INPUT_TYPE_ONEDRIVE = 'type-onedrive';
var INPUT_TYPE_INPUT_ID = 'type-input-id';
var INPUT_TYPE_DROPBOX = 'type-dropbox';

export { INPUT_TYPE_EXTERNAL_URL };
export { INPUT_TYPE_FILE_UPLOAD };
export { INPUT_TYPE_GDRIVE };
export { INPUT_TYPE_ONEDRIVE };
export { INPUT_TYPE_INPUT_ID };
export { INPUT_TYPE_DROPBOX };

var INPUT_TYPES = [
    INPUT_TYPE_EXTERNAL_URL,
    INPUT_TYPE_FILE_UPLOAD,
    INPUT_TYPE_GDRIVE,
    INPUT_TYPE_INPUT_ID,
    INPUT_TYPE_DROPBOX,
    INPUT_TYPE_ONEDRIVE,
];

export { INPUT_TYPES };

var SET_PASSWORD_ERROR_UNKNOWN_ERROR = 'set-password-error-unknown-error';
var SET_PASSWORD_ERROR_ALREADY_SETTING = 'set-password-error-already-setting';
var SET_PASSWORD_ERROR_PASSWORD_MISSING = 'set-password-error-password-missing';
var SET_PASSWORD_ERROR_WRONG_PASSWORD = 'set-password-error-wrong-password';

export { SET_PASSWORD_ERROR_UNKNOWN_ERROR };
export { SET_PASSWORD_ERROR_ALREADY_SETTING };
export { SET_PASSWORD_ERROR_PASSWORD_MISSING };
export { SET_PASSWORD_ERROR_WRONG_PASSWORD };

// these values must be the same as in user_errors.yml in the download project!
var UPLOADSERVER_ERROR_JOB_CAN_NOT_BE_MODIFIED = 2;
var UPLOADSERVER_ERROR_FILE_TOO_LARGE = 3;
var UPLOADSERVER_ERROR_EMPTY_OR_INVALID_FILE = 5;
var UPLOADSERVER_ERROR_PROBLEM_UPLOADING_FILE = 6;
var UPLOADSERVER_ERROR_UNSUPPORTED_CONTENT_RANGE_HEADER = 8;
var UPLOADSERVER_ERROR_SUM_OF_UPLOADS_TOO_LARGE = 34;
var UPLOADSERVER_ERROR_UNKNOWN = 99;

export { UPLOADSERVER_ERROR_JOB_CAN_NOT_BE_MODIFIED };
export { UPLOADSERVER_ERROR_FILE_TOO_LARGE };
export { UPLOADSERVER_ERROR_EMPTY_OR_INVALID_FILE };
export { UPLOADSERVER_ERROR_PROBLEM_UPLOADING_FILE };
export { UPLOADSERVER_ERROR_UNSUPPORTED_CONTENT_RANGE_HEADER };
export { UPLOADSERVER_ERROR_SUM_OF_UPLOADS_TOO_LARGE };
export { UPLOADSERVER_ERROR_UNKNOWN };

var UPLOADSERVER_ERRORS = [
    UPLOADSERVER_ERROR_JOB_CAN_NOT_BE_MODIFIED,
    UPLOADSERVER_ERROR_FILE_TOO_LARGE,
    UPLOADSERVER_ERROR_EMPTY_OR_INVALID_FILE,
    UPLOADSERVER_ERROR_PROBLEM_UPLOADING_FILE,
    UPLOADSERVER_ERROR_UNSUPPORTED_CONTENT_RANGE_HEADER,
    UPLOADSERVER_ERROR_SUM_OF_UPLOADS_TOO_LARGE,
    UPLOADSERVER_ERROR_UNKNOWN,
];

export { UPLOADSERVER_ERRORS };
